import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing4 --size-M" type={"SiteHeader"} fixed={true} layout={"3"} transparent={false} backgroundColor={"rgba(74,144,226,1)"} social={{"facebook":"","instagram":"","twitter":""}} title={{"color":"rgba(255,255,255,1)","content":"MINIBAGRY-OSTRAVA.CZ","className":"fs--20 w--700 ls--002"}} button={{"content":"Kontakt","page":"/kontakt","section":"uvod","className":"btn-box btn-box--shape3"}} item={{"color":"rgba(255,255,255,1)","colorHover":"rgba(74,123,247,1)","className":"fs--20"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}