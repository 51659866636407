export default {
  "": {
    "id": 4418,
    "temp_key": "",
    "site_id": 30951,
    "name": "",
    "items": [
      {
        "id": "dnprxwh1yewi",
        "use": "page",
        "name": "Ceník",
        "page": "/",
        "section": "cenik"
      }
    ]
  }
}